import React, { Suspense, lazy } from 'react';
// 3rd-party
import { Switch, Route } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
// pages
const HomePage = lazy(() => import('../pages/HomePage'));
const TheCompany = lazy(() => import('../pages/TheCompany'));
const Vision = lazy(() => import('../pages/Vision'));
const Manufacturing = lazy(() => import('../pages/Manufacturing'));
const Equipment = lazy(() => import('../pages/Equipment'));
const QualityScope = lazy(() => import('../pages/QualityScope'));
const Services = lazy(() => import('../pages/Services'));
const Showcase = lazy(() => import('../pages/Showcase'));

const spinnerStyle = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };


export default function routes() {
    return (
        <>
            <Suspense fallback={<ScaleLoader color={"#000000"} size={40} css={spinnerStyle} />}>
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    {/*<Route exact path="/about-us" component={AboutUs} />*/}
                    <Route exact path="/the-company" component={TheCompany} />
                    <Route exact path="/vision" component={Vision} />
                    {/*<Route exact path="/mission" component={Mission} />*/}
                    <Route exact path="/manufacturing" component={Manufacturing} />
                    <Route exact path="/quality-scope" component={QualityScope} />
                    {/*<Route exact path="/contact-us" component={ContactUs} />*/}
                    <Route exact path="/equipment" component={Equipment} />
                    <Route exact path="/services" component={Services} />
                    <Route exact path="/showcase" component={Showcase} />
                </Switch>
            </Suspense>
        </>
    )
}
