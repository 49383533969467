import React from "react";
import '../node_modules/bootstrap-4-react/dist/bootstrap-4-react';
import Routes from './routes/routes';
// 3rd-party

function App() {
  return (
    <>
      <Routes/>
    </>
  )
}

export default App;
